import logo from './logo.svg';
import './App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {checkRedirect, initSdk, invoke, asyncCall, call, SignRes} from 'wecom-sidebar-jssdk';


function App() {
const  [user_id, setUserId] = React.useState('init');
const  [error, setError] = React.useState('');

React.useEffect(() => {
    // (async function init() {
    //     const res1 = await invoke('getCurExternalContact');
    //     setUserId(res1.userId);
    // })()
}, [])

 const handleClick = async () => {
       const res1 = await invoke('getCurExternalContact');
        setUserId(res1.userId);
 }
 
  const handleSend = async () => {
      try{
         const res1 = await asyncCall('openEnterpriseChat', {
            // userIds: ['GaoShanYangZhi'].join(';'),
            groupName:"",
            externalUserIds: 'wmE3gOTAAAZ_XUkFjTKH5dMhhC_XwCxg',
       });  
      }catch(e){
          setError(e.toString())
      }
      
       
 }
 const selectExternalContact = async () => {
     await invoke('selectExternalContact', {"filterType": 0});
 }
 const sendBatch = async () => {
    await invoke('shareToExternalContact', {
      externalUserIds: ["wmE3gOTAAAnw9rgfbF6UgIrtqZI2JiMQ"],
      text: {
            content:"客户端群发测试1",    // 文本内容
      },
      attachments: [
	        {
            	  msgtype: "image",    // 消息类型，必填
				  image: {
						mediaid: "30D9OrsxZ02tAoIP7HxGUuq0HSLFu6NFy8q_UyMc3vVcul8rSXj5BfQIwclFQzkTWXaZ0VVEboG-LokLAfceWNA",      // 图片的素材id
				  },
			}
	    ]
    });
 }
 
  return (
      <>
    <div className="App">
        {user_id}
    </div>
     <div className="App2">
        {error}
    </div>
    <button onClick={handleClick}>获取外部联系人userId</button>
    <button onClick={handleSend}>打开会话2</button>
    <button onClick={selectExternalContact}>selectExternalContact</button>
    <button onClick={sendBatch}>消息群发</button>
    </>
  );
}

export default App;
